import styled from 'styled-components';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Col, Row } from 'react-bootstrap';
import React from 'react';

const TimesRow = styled(Row)`
  font-size: 17px;
`;
const WrapperRow = styled(Row)`
  display: flex;
  align-items: center;
  font-weight: ${(props) => (props.istoday ? '700' : '400')};

  ${TimesRow} {
    font-weight: ${(props) => (props.istoday ? '700' : '300')};

    font-size: 15px;
    letter-spacing: 0.58;
    @media (min-width: 768px) {
      font-size: 17px;
      letter-spacing: 0.67;
    }
  }
`;

const DayNameCol = styled(Col)`
  align-self: start;

  font-size: 15px;
  letter-spacing: 0.67;
  @media (min-width: 768px) {
    font-size: 17px;
    letter-spacing: 0.75;
  }
`;

const indexUsToEu = (index) => {
  return index === 6 ? 0 : index + 1;
};

function OpeningTimesListEntry({ dayName, isToday, openingTimesArr, isSpecialOpeningDay = false }) {
  return (
    <WrapperRow istoday={isToday ? 1 : 0}>
      <DayNameCol xs={3}>{dayName}</DayNameCol>
      <Col xs={9}>
        <TimesRow className="text-center">
          {openingTimesArr?.length === 0 ? (
            <Col xs={6}>
              <em>geschlossen</em>
            </Col>
          ) : (
            <>
              {openingTimesArr.map((time, index) => (
                <Col xs={6} key={`${dayName}-${index}`}>
                  {isSpecialOpeningDay ? (
                    <>
                      {dayjs(time.timeFrom).locale('de').format('HH:mm')} -{' '}
                      {dayjs(time.timeTo).locale('de').format('HH:mm')}
                    </>
                  ) : (
                    <>
                      {dayjs(time.from).locale('de').format('HH:mm')} -{' '}
                      {dayjs(time.to).locale('de').format('HH:mm')}
                    </>
                  )}
                </Col>
              ))}
            </>
          )}
        </TimesRow>
      </Col>
    </WrapperRow>
  );
}

OpeningTimesListEntry.propTypes = {
  dayName: PropTypes.string.isRequired,
  openingTimesArr: PropTypes.array,
  isToday: PropTypes.bool.isRequired,
  isSpecialOpeningDay: PropTypes.bool,
};

export default OpeningTimesListEntry;
