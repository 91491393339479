import PropTypes from 'prop-types';

import styled from 'styled-components';
import Icon from './Icon';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  text-align: left;
  align-items: center;

  h4 {
    font-weight: 400;
    margin: 0;

    font-size: 17px;
    letter-spacing: 0.75px;
    @media (min-width: 768px) {
      font-size: 19px;
      letter-spacing: 0.84px;
    }
  }
  p {
    margin: 0;
    font-weight: 300;

    letter-spacing: 0.53px;
    font-size: 12px;
    @media (min-width: 768px) {
      letter-spacing: 0.67px;
      font-size: 15px;
    }
  }
`;

const TextDetailsWrapper = styled.div``;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: 1px solid black;
  margin-right: 15px;

  flex-shrink: 0;
  flex-grow: 0;
`;

function ContactDetailComponent({ iconName, iconStyle, title, subtitle, ...rest }) {
  return (
    <Wrapper {...rest}>
      <IconWrapper>
        <Icon name={iconName} style={iconStyle || {}} />
      </IconWrapper>
      <TextDetailsWrapper>
        <h4>{title}</h4>
        <p>{subtitle}</p>
      </TextDetailsWrapper>
    </Wrapper>
  );
}

ContactDetailComponent.propTypes = {
  style: PropTypes.object,
  iconName: PropTypes.string.isRequired,
  iconStyle: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ContactDetailComponent;
