import styled from 'styled-components';
import Image from 'next/image';
import rewriteAssetPath from '../util/rewriteAssetPath';
import { useContext } from 'react';
import RestaurantContext from '../Context/RestaurantContext';
import CartContext from '../Context/CartContext';
import { Container } from 'react-bootstrap';
import CartIcon from './Cart/CartIcon';
import Link from 'next/link';
import UserAvatarComponent from './MemberArea/UserAvatarComponent';

const LogoHeadWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 1;
  width: 100%;
  height: 150px;

  justify-content: center;
`;

const LogoBgWrapper = styled.div`
  display: flex;
  position: relative;

  height: 100%;
  background-color: white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  padding: 10px;
`;

const LogoWrapper = styled.div`
  position: relative;

  max-height: 130px;
  width: 200px;

  img {
    object-fit: contain;
    object-position: center;
  }
`;

const HeadIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  pointer-events: none;

  /* padding-top: 15px; */
  @media (min-width: 768px) {
    /* padding-top: 30px; */
  }
`;
const GrowDiv = styled.div`
  flex-grow: 1;
`;

const IconsBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  justify-self: flex-end;
  align-items: center;
  pointer-events: all;

  #cart {
    height: 30px;
    @media (min-width: 768px) {
      height: 38px;
    }
  }
`;
const CartIconWrapper = styled.div``;

const StyledA = styled.a`
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
`;

function LogoHeader(props) {
  const { restaurantProps: storeProps, env, ...restaurantContext } = useContext(RestaurantContext);
  const {
    cart: { meta },
  } = useContext(CartContext);

  return (
    <>
      <LogoHeadWrapper>
        <LogoBgWrapper>
          <LogoWrapper>
            <Link href="/" passHref legacyBehavior>
              <StyledA>
                <Image
                  src={rewriteAssetPath(storeProps.platformLogoPath, env)}
                  alt={`Logo ${storeProps.restaurantName}`}
                  priority
                  fill
                  sizes="200px"
                />
              </StyledA>
            </Link>
          </LogoWrapper>
        </LogoBgWrapper>
      </LogoHeadWrapper>
      <HeadIconWrapper>
        <Container style={{ display: 'flex' }}>
          <GrowDiv />
          <IconsBox>
            <CartIconWrapper>
              <CartIcon count={meta.items} white />
            </CartIconWrapper>

            <UserAvatarComponent />
          </IconsBox>
        </Container>
      </HeadIconWrapper>
    </>
  );
}

export default LogoHeader;
