import React from 'react';
import { useIntlConfig } from '../Context/IntlProvider';

const Currency = ({ as: Component = 'span', children }) => {
  const { locale, options } = useIntlConfig();

  const formattedPrice = new Intl.NumberFormat(locale, {
    ...options, // Spread options here to include currency, maximumFractionDigits, etc.
  }).format(children);

  return <Component>{formattedPrice}</Component>;
};

export default Currency;
