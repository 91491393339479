import styled from 'styled-components';
import Icon from './Icon';

const Wrapper = styled.div`
  height: 30px;
  margin-bottom: 30px;

  &::after {
    content: '';
    display: block;
    clear: both;
  }
`;
const IconWrapper = styled.div`
  position: relative;
  height: 100%;
  float: left;
  margin-right: 15px;
`;

function IconsLine(props) {
  return (
    <Wrapper {...props}>
      <IconWrapper>
        <Icon style={{ height: '100%' }} name="position" />
      </IconWrapper>
      <IconWrapper className="clearfix">
        <Icon style={{ height: '100%' }} name="foodmenu" />
      </IconWrapper>
      <IconWrapper>
        <Icon style={{ height: '100%' }} name="scooter-min" />
      </IconWrapper>
    </Wrapper>
  );
}

IconsLine.propTypes = {};

export default IconsLine;
