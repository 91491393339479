import styled from 'styled-components';
import PropTypes from 'prop-types';
import Currency from '/util/Currency.js';

import { Col, Row } from 'react-bootstrap';
import { memo, useEffect, useState } from 'react';

const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 15px;
  text-align: left;

  &:nth-child(odd) {
    background-color: #fafafb;
  }
`;

const PriceOverviewRow = styled(Row)``;

const FromPriceCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-weight: 400;

  font-size: 16px;
  letter-spacing: 0.71px;
`;

const FromDeliveryFeeCol = styled(Col)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-weight: 300;
  font-size: 13px;

  @media (min-width: 768px) {
    font-size: 14px;
    font-weight: 300;
  }
`;

const DetailsRow = styled(Row)`
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  @media (min-width: 768px) {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.67px;
  }
`;

const CityNamesCol = styled(Col)`
  padding-bottom: 10px;
  @media (min-width: 768px) {
    padding-bottom: 15px;
  }
`;
const ZipCol = styled(Col)`
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 0.62px;
  @media (min-width: 768px) {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.62px;
  }
`;

const ShowMoreSpan = styled.span`
  font-size: 0.9rem;
  font-style: italic;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }
`;

function DeliveryRegionListEntry({ minOrderValueFrom, deliveryPriceFrom, cityNameList, zipList }) {
  const maxCityListLength = 10;
  const [moreCities, setMoreCities] = useState();
  const [cityList, setCityList] = useState();
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (cityNameList?.length > maxCityListLength) {
      var visibleCities = cityNameList.slice(0, maxCityListLength);
      var moreCities = cityNameList.slice(maxCityListLength + 1);
      setCityList(visibleCities);
      setMoreCities(moreCities);
    } else {
      setCityList(cityNameList);
      setMoreCities([]);
    }
  }, [cityNameList]);

  return (
    <Wrapper>
      <DetailsRow>
        <CityNamesCol xs={12}>
          {cityList && cityList.join(', ')}
          {moreCities?.length > 0 && !showMore && (
            <ShowMoreSpan onClick={() => setShowMore(true)}>
              {' '}
              ...und {moreCities.length} weitere
            </ShowMoreSpan>
          )}
          {moreCities && moreCities.length > 0 && showMore && (
            <span>, {moreCities.join(', ')}</span>
          )}
        </CityNamesCol>
        <ZipCol xs={12}>
          PLZ:&nbsp;
          {zipList && zipList.length > 0 && zipList.join(', ')}
        </ZipCol>
      </DetailsRow>

      <PriceOverviewRow>
        <FromDeliveryFeeCol xs={7}>
          <span>
            ab <Currency as="span">{deliveryPriceFrom}</Currency> Liefergebühr
          </span>
        </FromDeliveryFeeCol>

        <FromPriceCol xs={5}>
          <span>
            ab <Currency as="span">{minOrderValueFrom}</Currency>
          </span>
        </FromPriceCol>
      </PriceOverviewRow>
    </Wrapper>
  );
}

DeliveryRegionListEntry.propTypes = {
  cityNameList: PropTypes.arrayOf(PropTypes.string).isRequired,
  zipList: PropTypes.arrayOf(PropTypes.string).isRequired,
  minOrderValueFrom: PropTypes.number.isRequired,
  deliveryPriceFrom: PropTypes.number.isRequired,
};

export default memo(DeliveryRegionListEntry);
